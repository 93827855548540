import type { FC } from 'react';
import React, { Fragment, useContext, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	VIEW_INLINE_COMMENT_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { useCommentContentDispatchContext } from '@confluence/comment-context';
import { getLogger } from '@confluence/logger';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import {
	InlineCommentsQuery,
	CommentButtonRefetchQuery,
	ResolveInlineCommentMutation,
	DeleteInlineCommentMutation,
} from '@confluence/inline-comments-queries';
import type {
	SitePermissionType,
	InlineCommentsQueryType,
} from '@confluence/inline-comments-queries';
import { CommentBody, CommentErrorMessage } from '@confluence/inline-comments-common';
import type {
	Comment,
	Reply,
	CommentMode,
	InlineCommentError,
	UserToMention,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { removeMarkInDOM } from '@confluence/comments-util/entry-points/domUtils';
import { END } from '@confluence/navdex';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';

import { getCustomFMPDuration } from '../inlineCommentsUtils';
import {
	FOCUSED_INLINE_COMMENT_METRIC,
	RENDERER_INLINE_COMMENT_RENDER_METRIC,
} from '../perf.config';

import { CreateComment } from './CreateComment';
import { EditComment } from './EditComment';

type InlineCommentProps = {
	pageId: string;
	pageType: string;
	spaceId: string;
	shouldShowFocusedComment: boolean;
	shouldOpenCommentEditor: boolean;
	shouldShowReplyEditor?: boolean;
	userToMention?: UserToMention;
	onDeleteSuccess: () => void;
	userId?: string | null;
	comment?: Comment | Reply; // null in the case of "create"
	parentId?: string; // null in the case of "create"
	isReply: boolean;
	isFocused?: boolean;
	isFabricPage?: boolean;
	onNewCommentSuccess?: (newMarkerRef?: string) => void;
	onResolve?: (commentId: string, inlineMarkerRef: string) => void;
	lastFetchTime?: number;
	canUploadMedia: boolean;
	shouldAutofocus?: boolean;
	onAutoFocused?: () => void;
	isSSRFocusedComment?: boolean;
};

const logger = getLogger('inline-comment');

export const InlineComment: FC<InlineCommentProps> = ({
	pageId,
	pageType,
	spaceId,
	shouldShowFocusedComment,
	shouldOpenCommentEditor,
	shouldShowReplyEditor,
	userToMention,
	userId,
	onDeleteSuccess,
	comment,
	parentId,
	isReply,
	isFabricPage,
	onNewCommentSuccess,
	onResolve,
	lastFetchTime,
	canUploadMedia,
	shouldAutofocus,
	onAutoFocused,
	isSSRFocusedComment,
}) => {
	// We can infer the create/edit comment editor should be shown if no comment is passed in
	const [mode, setMode] = useState<CommentMode>(comment ? 'view' : 'create');
	const [errorState, setErrorState] = useState<InlineCommentError | null>(null);
	const [isRemoving, setIsRemoving] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { resetContentChanged, onCommentContentReady } = useCommentContentDispatchContext();

	const { canAddComments } = useAddCommentPermissionCheck(pageId);

	// Succeed view inline comment when the top level comment renders
	// Important: Renders after CommentBody, so if there is an error, the experience will fail first
	useEffect(() => {
		if (!isReply && comment) {
			experienceTracker.succeed({
				name: VIEW_INLINE_COMMENT_EXPERIENCE,
			});
		}
	}, [isReply, comment, experienceTracker]);

	const { focusedCommentId, editCommentId } = useInlineCommentQueryParams();

	const [deleteComment] = useMutation(DeleteInlineCommentMutation, {
		refetchQueries: [{ query: CommentButtonRefetchQuery, variables: { pageId } }],
	});
	const [resolveInlineCommentFn] = useMutation(ResolveInlineCommentMutation, {
		refetchQueries: [{ query: CommentButtonRefetchQuery, variables: { pageId } }],
	});

	// Analytic event for deleting inline comment
	const fireTrackEvent = (action: string, id: string) => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: 'viewPageScreen',
				containerType: 'space',
				containerId: spaceId,
				objectType: 'page',
				objectId: pageId,
				action,
				actionSubject: 'comment',
				actionSubjectId: id,
				attributes: {
					commentType: 'inline',
					mode: 'view',
					context: 'default',
					navdexPointType: END,
				},
			},
		}).fire();
	};

	const updateCache = (id, type) => {
		return (cache) => {
			try {
				const data: InlineCommentsQueryType = cache.readQuery({
					query: InlineCommentsQuery,
					variables: { pageId },
				});

				const nodes = data.comments?.nodes || [];

				const commentIndex = nodes.findIndex((comment) => comment?.id === id);

				if (commentIndex !== -1) {
					const newNodes = [...nodes];
					newNodes.splice(commentIndex, 1);

					cache.writeQuery({
						query: InlineCommentsQuery,
						variables: { pageId },
						data: {
							comments: {
								nodes: newNodes,
								__typename: 'PaginatedCommentList',
							},
						},
					});
				}
			} catch (err) {
				logger.error`An Error occured when updating cache to ${type} comment - ${err}`;
			}
		};
	};

	const handleResolveComment = (commentId, inlineMarkerRef) => {
		const mtnVariables = {
			variables: { commentId, resolved: true },
		};

		resolveInlineCommentFn({
			...mtnVariables,
			update: updateCache(commentId, 'resolve'),
		})
			.then(() => {
				onResolve && onResolve(commentId, inlineMarkerRef);

				// succeed resolve experience
				experienceTracker.succeed({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				});
				// analytic event for successful resolve
				fireTrackEvent('resolved', commentId);
			})
			.catch((error) => {
				// Set the error for resolve action
				setErrorState({ error, action: 'resolve' });
			});
	};

	const handleDeleteComment = (id, markerRef) => {
		setIsRemoving(true);

		const deleteMutationVariables = {
			variables: {
				commentIdToDelete: id,
			},
			update: updateCache(id, 'delete'),
		};

		deleteComment(deleteMutationVariables)
			.then(({ data: { deleteComment: success } }) => {
				if (success) {
					// Remove the mark if it's a top-level comment
					if (!isReply) {
						removeMarkInDOM(markerRef);
					}
					onDeleteSuccess && onDeleteSuccess();

					// succeed experience for deleting inline comment
					experienceTracker.succeed({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
					});
					// analytic event for deleting a comment
					fireTrackEvent('deleted', id);
				} else {
					const error = new Error('Delete failed');
					// fail and stop delete experience
					experienceTracker.stopOnError({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
						error,
					});

					throw error;
				}
			})
			.catch((error) => {
				// Remove the fade on the container and set the error state for delete
				setIsRemoving(false);
				setErrorState({ error, action: 'delete' });
			});
	};

	useEffect(() => {
		if (comment) {
			setMode('view');
			setErrorState(null);
		}
	}, [comment]);

	useEffect(() => {
		if (shouldShowFocusedComment || isSSRFocusedComment) {
			const { duration, isQuickReloadComment } = getCustomFMPDuration();
			if (duration) {
				FOCUSED_INLINE_COMMENT_METRIC.markFMP(duration);
				if (isQuickReloadComment) {
					FOCUSED_INLINE_COMMENT_METRIC.stop({
						customData: { isQuickReloadComment },
						stopTime: duration,
					});
				}
			}

			FOCUSED_INLINE_COMMENT_METRIC.stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSSRFocusedComment, shouldShowFocusedComment, focusedCommentId]);

	useEffect(() => {
		if (shouldOpenCommentEditor && comment) {
			const {
				id,
				permissions: { isEditable },
			} = comment;

			if (editCommentId === id && isEditable) {
				setMode('edit');
			}
		}
	}, [shouldOpenCommentEditor, editCommentId, comment]);

	const handleEditComment = () => {
		setMode('edit');
	};

	const resetComment = () => {
		resetContentChanged();
		setMode('view');
	};

	const handleOnContentReady = useCallback(() => {
		onCommentContentReady();
	}, [onCommentContentReady]);

	if (comment) {
		const {
			id: commentId,
			body: { value: content },
			links: { webui: dateUrl },
			parentId,
			permissions,
			version: { number: commentVersion, when: date },
		} = comment;

		let authorId: string | undefined;
		let avatarUrl: string | undefined;
		let displayName: string | undefined;
		let isCurrentUserAnonymous = true;
		let permissionType: SitePermissionType | undefined;
		const isUnread: boolean = false;

		// If the user is not anonymous, pull out the author data
		if (userId) {
			authorId = comment.author?.accountId;
			avatarUrl = comment.author?.profilePicture.path;
			displayName = comment.author?.displayName;
			isCurrentUserAnonymous = false;
			permissionType = comment.author?.permissionType;
		}

		// Extract Comment-specific fields

		let inlineMarkerRef;

		const { replies } = comment as Comment;

		if (isSSRFocusedComment) {
			inlineMarkerRef =
				window?.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__?.['focusedComment']?.['markerRef'];
		} else {
			inlineMarkerRef = comment?.location?.inlineMarkerRef;
		}

		const isFocused = isReply && focusedCommentId === commentId;

		// If the comment exists and the mode is "view" show the comment body, otherwise show "edit"
		return mode === 'view' ? (
			<Fragment>
				{errorState && (
					<CommentErrorMessage
						error={errorState.error}
						action={errorState.action}
						commentId={commentId}
					/>
				)}
				<CommentBody
					pageId={pageId}
					pageType={pageType}
					avatarUrl={avatarUrl}
					commentId={commentId}
					content={content}
					date={date}
					displayName={displayName}
					permissions={permissions}
					dateUrl={dateUrl}
					userId={authorId}
					deleteComment={() => handleDeleteComment(commentId, inlineMarkerRef)}
					editComment={handleEditComment}
					isReply={isReply}
					numReplies={replies && replies.length}
					isRemoving={isRemoving}
					resolveComment={() => handleResolveComment(commentId, inlineMarkerRef)}
					isFocused={isFocused}
					shouldScrollIntoView={shouldShowFocusedComment}
					isFabricPage={isFabricPage}
					isCurrentUserAnonymous={isCurrentUserAnonymous}
					mode="view"
					shouldAutofocus={shouldAutofocus}
					onAutoFocused={onAutoFocused}
					permissionType={permissionType}
					onRendered={handleOnContentReady}
					onComplete={handleOnContentReady}
					isUnread={isUnread}
				/>
				{!(shouldShowFocusedComment || isSSRFocusedComment) && comment?.id && (
					<PageSegmentLoadEnd
						key={`stop-${comment?.id}`}
						metric={RENDERER_INLINE_COMMENT_RENDER_METRIC}
					/>
				)}
			</Fragment>
		) : (
			<EditComment
				pageId={pageId}
				pageType={pageType}
				spaceId={spaceId}
				resetComment={resetComment}
				avatarUrl={avatarUrl}
				commentId={commentId}
				content={content}
				displayName={displayName}
				version={commentVersion}
				userId={authorId}
				parentId={parentId}
				isReply={isReply}
				isFabricPage={isFabricPage}
				mode="view"
				canUploadMedia={canUploadMedia}
			/>
		);
	}

	return canAddComments ? (
		<CreateComment
			pageId={pageId}
			pageType={pageType}
			spaceId={spaceId}
			isFabricPage={isFabricPage}
			isReply={isReply}
			userId={userId}
			parentId={parentId}
			onNewCommentSuccess={onNewCommentSuccess}
			shouldShowReplyEditor={shouldShowReplyEditor}
			userToMention={userToMention}
			lastFetchTime={lastFetchTime}
			mode="view"
			canUploadMedia={canUploadMedia}
		/>
	) : null;
};
