export const FOCUSED_INLINE_COMMENT_QUICK_RELOAD_START_MARK =
	'focused-inline-comment-quick-reload.render-start';

export const FOCUSED_INLINE_COMMENT_QUICK_RELOAD_END_MARK =
	'focused-inline-comment-quick-reload.render-end';

export const FOCUSED_INLINE_COMMENT_SSR_MARK = 'focused-inline-comment-fmp-ssr.rendered';

export const IMPROVED_INLINE_COMMENTS_NAVIGATION_FF =
	'confluence.frontend.inline.comments.improved.navigation';

export const MENTION_IN_INLINE_COMMENT_REPLY_FROM_QUERY_PARAMS =
	'confluence.frontend.mention.in.reply.from.query.params';
