import type { FC } from 'react';
import { useMutation } from '@apollo/react-hooks';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N200 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { ErrorDisplay } from '@confluence/error-boundary';
import { AUTO_DISMISS_RESOLVE_NOTIFICATION_DELAY } from '@confluence/inline-comments-common/entry-points/inlineCommentConstants';
import type {
	Comment,
	UserToMention,
	Reply,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { MarkInlineCommentsReadMutation } from '@confluence/inline-comments-queries';
import {
	VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useCommentContentContext } from '@confluence/comment-context';
import type { FloatingBannerStateContainer } from '@confluence/banners';
import type { DialogsStateContainer } from '@confluence/dialogs';
import type { CreateInlineCommentHighlight, ResolvedComment } from '@confluence/comment-context';
import { clearCommentDraft } from '@confluence/comment';
import {
	ResolvedCommentNotification,
	CommentNavigation,
	ReopenedMessage,
	CollapsedReplies,
	InlineCommentFramework,
} from '@confluence/inline-comments-common';
import { getHighlightOffset } from '@confluence/comments-util';
import {
	SidebarContainer,
	ReplyListContainer,
	NewReplyContainer,
	InlineHeaderButton,
	SidebarHeader,
} from '@confluence/inline-comments-common/entry-points/styled';
import { useIsPageCommentsAutoReloadEnabled } from '@confluence/page-comments/entry-points/feature-flags';

import { InlineComment } from './InlineComment';

type InlineCommentSidebarProps = {
	pageId: string;
	pageType: string;
	spaceId: string;
	onClose: () => void;
	onDeleteSuccess: () => void;
	onNewCommentSuccess: (newMarkerRef?: string) => void;
	canReplyToComment: boolean;
	shouldShowFocusedComment: boolean;
	shouldOpenCommentEditor: boolean;
	shouldShowReplyEditor: boolean;
	mentionCommentId?: string;
	userId?: string | null;
	comment?: Comment;
	newCommentHighlight?: CreateInlineCommentHighlight;
	isFabricPage?: boolean;
	refetchComments: () => void;
	navigateToComment: (action: 'next' | 'previous', comment: Comment) => void;
	currentCommentIndex?: number;
	commentsCount?: number;
	dialogs: DialogsStateContainer;
	floatingBanners: FloatingBannerStateContainer;
	lastFetchTime: number;
	canUploadMedia: boolean;
	resolvedComment: ResolvedComment | null;
	setResolvedComment: React.Dispatch<React.SetStateAction<ResolvedComment | null>>;
	isSSRFocusedComment?: boolean;
	resetActiveHighlight: () => void;
	isUnreadCommentsEnabled?: boolean;
};

type CloseButtonProps = {
	isCreate: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const CloseButtonContainer = styled.div<CloseButtonProps>(`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    ${(props: CloseButtonProps) =>
			props.isCreate ? 'position: absolute; top: 12px; right: 12px;' : 'margin-top: 0px;'}
`);

export const InlineCommentSidebar: FC<InlineCommentSidebarProps> = ({
	pageId,
	pageType,
	spaceId,
	onClose,
	onDeleteSuccess,
	onNewCommentSuccess,
	canReplyToComment,
	shouldShowFocusedComment,
	shouldOpenCommentEditor,
	shouldShowReplyEditor,
	mentionCommentId,
	userId,
	comment,
	newCommentHighlight,
	isFabricPage,
	refetchComments,
	navigateToComment,
	currentCommentIndex,
	commentsCount,
	dialogs,
	floatingBanners,
	lastFetchTime,
	canUploadMedia,
	isSSRFocusedComment,
	resolvedComment,
	setResolvedComment,
	resetActiveHighlight,
	isUnreadCommentsEnabled = false,
}) => {
	const { formatMessage } = useIntl();
	// Setting initial value to 300 to match the EIC component
	const [topOffset, setTopOffset] = useState(300);
	const [isSidebarShown, setIsSidebarShown] = useState(false);
	const [isReplyChainCollapsed, setIsReplyChainCollapsed] = useState(
		!shouldShowFocusedComment && !shouldOpenCommentEditor,
	);
	const [isAddingNewComment, setAddingNewComment] = useState(false);
	const [autoFocusedCommentId, setAutoFocusedCommentId] = useState('');
	const [newCommentTimestamp, setNewCommentTimestamp] = useState(0);
	const [userToMention, setUserToMention] = useState<UserToMention | undefined>(undefined);

	// States for resolve notification auto dismissal and animation
	const [isAutoDismissResolve, setIsAutoDismissResolve] = useState(false);

	const { hasContentChanged } = useCommentContentContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPageCommentsAutoReloadEnabled = useIsPageCommentsAutoReloadEnabled();

	const [markInlineCommentsRead, { error: markCommentReadError }] = useMutation(
		MarkInlineCommentsReadMutation,
	);

	useEffect(() => {
		// COMMENTS-1878 - Send an "inlineCommentsSidebar viewed" event if user is viewing a comment
		if (isSidebarShown && comment) {
			const replyCount = (comment.replies || []).length;
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'inlineCommentsSidebar',
					objectType: 'page',
					objectId: pageId,
					source: 'viewPageScreen', // Legacy is only shown for renderer
					attributes: {
						mode: 'view',
						framework: InlineCommentFramework.REACT,
						commentsDeckSize: replyCount + 1, // +1 for the parent comment
					},
				},
			}).fire();
		}
	}, [isSidebarShown, comment, createAnalyticsEvent, pageId]);

	useEffect(() => {
		if (isSidebarShown && resolvedComment) {
			const timer = setTimeout(() => {
				setIsAutoDismissResolve(true);
			}, AUTO_DISMISS_RESOLVE_NOTIFICATION_DELAY);
			return () => {
				clearTimeout(timer);
				// Dismiss resolved comment dialog when navigating to another page
				setResolvedComment(null);
			};
		}
	}, [resolvedComment, isSidebarShown, setResolvedComment]);

	const handleResolveNotificationAnimationEnd = () => {
		if (isAutoDismissResolve && isSidebarShown) {
			setIsSidebarShown(false);
			setIsAutoDismissResolve(false);
			setResolvedComment(null);
		}
	};

	useEffect(
		() => {
			if (!comment && resolvedComment) {
				setIsSidebarShown(true);
				return;
			}

			// If we don't have a comment or a selection, don't do anything
			if (!comment && !newCommentHighlight) {
				setIsSidebarShown(false);
				return;
			}

			let markOffsetTop;

			// We have a comment, use it's position to calculate the top
			if (comment) {
				let inlineMarkerRef;
				if (isSSRFocusedComment) {
					inlineMarkerRef =
						window?.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__?.['focusedComment']?.['markerRef'];
				} else {
					({ inlineMarkerRef } = comment.location);
				}
				setNewCommentTimestamp(0); // Null out the new comment timestamp

				markOffsetTop = getHighlightOffset(
					Boolean(isFabricPage),
					false,
					inlineMarkerRef,
					isPageCommentsAutoReloadEnabled,
				);
			} else {
				// If a new selection is made and committed, show the "create" editor
				if (newCommentHighlight) {
					// Validate we have a new highlight before setting the state to allow actual new highlights to come in
					const isNewHighlight = newCommentTimestamp === newCommentHighlight.timestamp;

					// If the timestamp has changed, set it here so we don't re-trigger it next time
					if (newCommentTimestamp !== newCommentHighlight.timestamp) {
						setNewCommentTimestamp(newCommentHighlight.timestamp);
					}

					markOffsetTop = getHighlightOffset(
						Boolean(isFabricPage),
						isSidebarShown && isNewHighlight,
						'',
						isPageCommentsAutoReloadEnabled,
					);

					// When creating a new comment, we need to reset the active highlights to null, otherwise the state will incorrectly identify any inline comments which were opened previously as still "active"
					resetActiveHighlight();
				}
			}

			/*Clean up Resolved comment */
			setResolvedComment(null);

			/* only set topOffset and sidebar if markOffsetTop is greater than 0 */
			if (markOffsetTop > 0) {
				setTopOffset(markOffsetTop);
				setIsSidebarShown(true);
				/**
				 * Scenario: A user clicks the Inline Comments Button in SSR and there are >=1 inline comments present.
				 * If there is an SSR click, deleting it here removes the loading spinner only when the first inline comment sidebar is open.
				 * There is similar logic to clean up this click in the scenario where there are no inline comments, found
				 * in CommentButton.tsx
				 */
				if (window?.__SSR_EVENTS_CAPTURE__?.inlineCommentButton) {
					delete window.__SSR_EVENTS_CAPTURE__.inlineCommentButton;
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			comment,
			newCommentHighlight,
			isFabricPage,
			resolvedComment,
			floatingBanners.state.floatingBanners, // When the floating banner hides/shows we need to reposition
			newCommentTimestamp,
			setNewCommentTimestamp,
			isSSRFocusedComment,
		],
	);

	useEffect(() => {
		// Collapse the reply chain when a new comment or highlight is made and no focus is needed
		setIsReplyChainCollapsed(!shouldShowFocusedComment && !shouldOpenCommentEditor);
	}, [comment, newCommentHighlight, shouldShowFocusedComment, shouldOpenCommentEditor]);

	const handleClose = useCallback(() => {
		const doClose = (skipDrafts = false) => {
			setIsSidebarShown(false);
			setIsReplyChainCollapsed(true);
			setResolvedComment(null);
			setNewCommentTimestamp(0);

			// Clear the comment drafts if not skipped
			if (!skipDrafts) {
				clearCommentDraft('inline', 'create');
				clearCommentDraft('inline', 'reply');
				clearCommentDraft('inline', 'edit');
			}
			onClose && onClose();
		};

		if (!hasContentChanged) {
			doClose(true);
			return;
		}

		dialogs.showModal(CommentWarningDialog, {
			onConfirm: doClose,
		});
	}, [onClose, hasContentChanged, dialogs, setResolvedComment]);

	useEffect(() => {
		if (!isAddingNewComment || !comment) {
			return;
		}

		const isNewComment = comment.replies.length === 0;

		const commentIdToAutofocus = isNewComment
			? comment.id
			: comment.replies[comment.replies.length - 1].id;

		setAutoFocusedCommentId(commentIdToAutofocus);
		setAddingNewComment(false);
	}, [comment, isAddingNewComment]);

	const onResolve = (commentId, inlineMarkerRef) => {
		setResolvedComment({ commentId, inlineMarkerRef });
	};

	const fireTrackEvent = (id: string) => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: 'viewPageScreen',
				objectType: 'page',
				objectId: pageId,
				action: 'undo',
				actionSubject: 'comment',
				actionSubjectId: id,
				attributes: {
					commentType: 'inline',
					mode: 'view',
					context: 'default',
				},
			},
		}).fire();
	};

	const handleNewCommentSave = useCallback(
		(newMarkerRef?: string) => {
			onNewCommentSuccess(newMarkerRef);
			setAddingNewComment(true);
		},
		[onNewCommentSuccess],
	);

	const onCommentAutoFocused = useCallback(() => setAutoFocusedCommentId(''), []);

	const reopenComment = (id) => {
		setResolvedComment(null);
		refetchComments();
		// analytic event for successful unresolve comment
		fireTrackEvent(id);
	};

	useEffect(() => {
		if (mentionCommentId) {
			const commentToReplyTo =
				comment?.id === mentionCommentId
					? comment
					: comment?.replies.find((c) => c.id === mentionCommentId);

			if (commentToReplyTo?.author.permissionType !== 'ANONYMOUS') {
				setUserToMention(commentToReplyTo?.author);
			}
		}
	}, [mentionCommentId, comment]);

	useEffect(() => {
		if (comment && !isUnreadCommentsEnabled) {
			const allCommentIds = new Set<string>();
			allCommentIds.add(comment.id);
			comment.replies?.forEach((reply: Reply) => {
				allCommentIds.add(reply.id);
			});

			//We want to call the mutation on all opened inline comments temporarily so cc-analytics can ingest 30 days of "viewed comments" events. This is so we have data around which comments to display as unread once the feature begins rolling out
			void markInlineCommentsRead({
				variables: { commentIds: [...allCommentIds] },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- updateUnreadCommentsListState in the dependency array will cause unnecessary re-renders
	}, [comment, isUnreadCommentsEnabled]);

	const renderReplies = useCallback(() => {
		if (!comment) {
			return null;
		}

		const hasReplies = comment.replies.length > 0;
		const shouldCollapseReplies =
			isReplyChainCollapsed && comment.replies.length > 4 && !isSSRFocusedComment;

		// If we need to collapse the reply list, we only show the last one
		const replies = shouldCollapseReplies ? comment.replies.slice(-1) : comment.replies;

		return (
			<Fragment>
				{hasReplies && (
					<ReplyListContainer data-testid="inline-comment-reply-container">
						{shouldCollapseReplies && (
							<CollapsedReplies
								numCollapsedReplies={comment.replies.length - 1}
								onClick={() => setIsReplyChainCollapsed(false)}
							/>
						)}
						{replies.map((reply) => (
							<InlineComment
								key={reply.id}
								pageId={pageId}
								pageType={pageType}
								spaceId={spaceId}
								shouldShowFocusedComment={shouldShowFocusedComment}
								shouldOpenCommentEditor={shouldOpenCommentEditor}
								userId={userId}
								comment={reply}
								parentId={comment.id}
								onDeleteSuccess={onDeleteSuccess}
								isReply
								canUploadMedia={canUploadMedia}
								shouldAutofocus={autoFocusedCommentId === reply.id}
								onAutoFocused={onCommentAutoFocused}
							/>
						))}
					</ReplyListContainer>
				)}
				{canReplyToComment && (
					<NewReplyContainer mode="view" data-testid="inline-comment-new-reply-container">
						<InlineComment
							pageId={pageId}
							pageType={pageType}
							spaceId={spaceId}
							shouldShowFocusedComment={shouldShowFocusedComment}
							shouldOpenCommentEditor={shouldOpenCommentEditor}
							shouldShowReplyEditor={shouldShowReplyEditor}
							userToMention={userToMention}
							userId={userId}
							parentId={comment.id}
							isReply
							onNewCommentSuccess={handleNewCommentSave}
							onDeleteSuccess={onDeleteSuccess}
							canUploadMedia={canUploadMedia}
						/>
					</NewReplyContainer>
				)}
			</Fragment>
		);
	}, [
		comment,
		canReplyToComment,
		pageId,
		pageType,
		spaceId,
		shouldShowFocusedComment,
		shouldOpenCommentEditor,
		shouldShowReplyEditor,
		userToMention,
		userId,
		isReplyChainCollapsed,
		handleNewCommentSave,
		onDeleteSuccess,
		onCommentAutoFocused,
		autoFocusedCommentId,
		canUploadMedia,
		isSSRFocusedComment,
	]);

	const resolvedUser = comment?.location?.inlineResolveProperties?.resolvedUser;
	const resolvedTime = comment?.location?.inlineResolveProperties?.resolvedTime;

	let highlightOffset = 0;

	/* Avoid flashing effect if we have highlightOffset already use it for focused comment only*/
	if (
		isSSRFocusedComment &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		highlightOffset =
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['markerRefOffset'];
	}

	const goToNextComment = () => {
		comment && navigateToComment('next', comment);
	};

	const goToPrevComment = () => {
		comment && navigateToComment('previous', comment);
	};

	return isSidebarShown || highlightOffset ? (
		<SidebarContainer
			isAutoDismissResolve={isAutoDismissResolve}
			id="inline-comment-sidebar"
			offset={topOffset || highlightOffset}
			mode="view"
			onAnimationEnd={handleResolveNotificationAnimationEnd}
			data-testid="inline-comment-sidebar"
		>
			{markCommentReadError && <ErrorDisplay error={markCommentReadError} />}
			<SidebarHeader
				shouldShowNavigation={Boolean(comment)}
				data-testid="inline-comment-sidebar-header"
			>
				{comment && (
					<CommentNavigation
						currentCommentIndex={currentCommentIndex}
						commentsCount={commentsCount}
						isSSRFocusedComment={isSSRFocusedComment}
						goToNextInlineComment={goToNextComment}
						goToPrevInlineComment={goToPrevComment}
					/>
				)}

				<Tooltip
					content={
						<FormattedMessage
							id="inline-comments.close.icon.tooltip"
							defaultMessage="Close sidebar (])"
							description="A tooltip message informing the user what the icon does on hover and its hotkey"
						/>
					}
					hideTooltipOnClick
					position="top"
				>
					<CloseButtonContainer isCreate={!comment}>
						<InlineHeaderButton
							appearance="subtle"
							testId="close-comment-sidebar-button"
							iconBefore={
								<CrossIcon
									label={formatMessage({
										id: 'inline-comments.close.icon.ariaLabel',
										description: 'aria-label for Close comment button',
										defaultMessage: 'Close comment sidebar',
									})}
									primaryColor={token('color.icon', N200)}
									size="small"
								/>
							}
							onClick={handleClose}
						/>
					</CloseButtonContainer>
				</Tooltip>
			</SidebarHeader>
			{resolvedComment ? (
				<ResolvedCommentNotification
					commentId={resolvedComment.commentId}
					reopenComment={() => reopenComment(resolvedComment.commentId)}
					pageId={pageId}
					inlineMarkerRef={resolvedComment.inlineMarkerRef}
					mode="view"
				/>
			) : (
				<Fragment>
					<InlineComment
						pageId={pageId}
						pageType={pageType}
						spaceId={spaceId}
						shouldShowFocusedComment={shouldShowFocusedComment}
						shouldOpenCommentEditor={shouldOpenCommentEditor}
						userId={userId}
						comment={comment}
						isFabricPage={isFabricPage}
						onNewCommentSuccess={handleNewCommentSave}
						onResolve={onResolve}
						onDeleteSuccess={onDeleteSuccess}
						lastFetchTime={lastFetchTime}
						isReply={false}
						canUploadMedia={canUploadMedia}
						shouldAutofocus={autoFocusedCommentId === comment?.id}
						onAutoFocused={onCommentAutoFocused}
						isSSRFocusedComment={isSSRFocusedComment}
					/>

					<ReopenedMessage resolvedUser={resolvedUser} resolvedTime={resolvedTime} />
					{renderReplies()}
					{/* Discover Inline Comment Success Experience */}
					<ExperienceSuccess
						name={VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE}
						attributes={{ pageHasInlineComments: true }}
					/>
				</Fragment>
			)}
		</SidebarContainer>
	) : null;
};
