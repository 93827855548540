import { getMark, measureMarks, getEntryByName, clearMarks } from '@confluence/performance';

import {
	FOCUSED_INLINE_COMMENT_QUICK_RELOAD_START_MARK,
	FOCUSED_INLINE_COMMENT_QUICK_RELOAD_END_MARK,
	FOCUSED_INLINE_COMMENT_SSR_MARK,
} from './constants';

export const getCustomFMPDuration = () => {
	/*measure comment open via SSR*/
	/* Mark is added in next/packages/ssr-utilities/inline-scripts/SSRfocusedInlineCommentScript.ts*/

	let focusedCommentDuration = getMark(FOCUSED_INLINE_COMMENT_SSR_MARK);

	let isQuickReloadComment;

	if (focusedCommentDuration) {
		clearMarks([FOCUSED_INLINE_COMMENT_SSR_MARK]);
	} else {
		/* In case of  Quick reload measure from reload (quick-reload/src/QuickReloadFlagsI18nProvider.tsx)
    to display comment*/

		isQuickReloadComment = getEntryByName(FOCUSED_INLINE_COMMENT_QUICK_RELOAD_START_MARK);

		if (isQuickReloadComment) {
			/*performance mark end*/
			window.performance.mark(FOCUSED_INLINE_COMMENT_QUICK_RELOAD_END_MARK);

			focusedCommentDuration = measureMarks(
				FOCUSED_INLINE_COMMENT_QUICK_RELOAD_START_MARK,
				FOCUSED_INLINE_COMMENT_QUICK_RELOAD_END_MARK,
			);

			clearMarks([
				FOCUSED_INLINE_COMMENT_QUICK_RELOAD_START_MARK,
				FOCUSED_INLINE_COMMENT_QUICK_RELOAD_END_MARK,
			]);
		}
	}

	return {
		duration: focusedCommentDuration,
		isQuickReloadComment: Boolean(isQuickReloadComment),
	};
};
