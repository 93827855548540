import type { FC } from 'react';
import React, { useCallback, useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import set from 'lodash/set';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentEditor } from '@confluence/comment';
import { useCommentContentDispatchContext } from '@confluence/comment-context';
import {
	InlineCommentsQuery,
	UpdateInlineCommentMutation,
} from '@confluence/inline-comments-queries';
import type { InlineCommentsQueryType } from '@confluence/inline-comments-queries';
import { CommentAuthor } from '@confluence/inline-comments-common';
import { EditorContainer } from '@confluence/inline-comments-common/entry-points/styled';
import {
	parseError,
	getTranslatedError,
	isUnexpectedError,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import type { InlineCommentsMode } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { END } from '@confluence/navdex';

type EditCommentProps = {
	pageId: string;
	pageType: string;
	spaceId: string;
	resetComment: () => void;
	avatarUrl?: string | null;
	commentId: string;
	content?: string;
	displayName?: string | null;
	version: number;
	userId?: string | null;
	parentId?: string;
	isReply?: boolean;
	isFabricPage?: boolean;
	mode: InlineCommentsMode;
	canUploadMedia?: boolean;
};

export const EditComment: FC<EditCommentProps> = ({
	pageId,
	pageType,
	spaceId,
	resetComment,
	userId,
	avatarUrl,
	commentId,
	content,
	displayName,
	parentId,
	version,
	isReply,
	mode,
	canUploadMedia,
}) => {
	const editorRef = useRef<HTMLDivElement | null>(null);

	const { onChange } = useCommentContentDispatchContext();

	const [updateInlineComment] = useMutation(UpdateInlineCommentMutation);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const handleError = (error: Error) => {
		const { message } = parseError(error);
		const translatedError = getTranslatedError(message, commentId);

		// WS-2629 - We only want to fail the experience for an error that is unexpected
		if (isUnexpectedError(translatedError)) {
			experienceTracker.stopOnError({
				error,
				name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			});
		}

		return Promise.reject({ error: translatedError });
	};

	const fireSuccessEvent = ({ id }) => {
		const analyticsObject = {
			containerType: 'space',
			containerId: spaceId,
			source: 'viewPageScreen',
			objectType: 'page',
			objectId: pageId,
			action: 'updated',
			actionSubject: 'comment',
			actionSubjectId: id,
			attributes: {
				commentType: 'inline',
				pageType,
				parentCommentId: parentId ?? null, // analytics event schema type expects string or null
				framework: 'REACT',
				context: mode === 'view-all' ? 'viewAllInlineComments' : 'default',
				navdexPointType: END,
			},
		};

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: analyticsObject,
		}).fire();
	};

	/**
	 * Calls update mutation and cache update when saving an edited comment
	 */
	const handleSave = (adf, onSuccess) => {
		experienceTracker.start({
			name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			attributes: {
				mode,
			},
		});

		const mutationVariables = {
			variables: {
				input: {
					commentId,
					version: version + 1,
					commentBody: {
						value: JSON.stringify(adf),
						representationFormat: 'ATLAS_DOC_FORMAT',
					},
				},
			},
			update: (cache) => {
				// View and View All mode updates by finding the selected comment from a list of all comments
				const data: InlineCommentsQueryType = cache.readQuery({
					query: InlineCommentsQuery,
					variables: { pageId },
				});

				const nodes = data.comments?.nodes || [];

				let updatedComment;

				if (isReply) {
					// For a reply, find the parent and then grab the updated reply
					const parent = nodes.find((comment) => comment?.id === parentId);

					updatedComment = parent?.replies.find((reply) => reply?.id === commentId);
				} else {
					updatedComment = nodes.find((comment) => comment?.id === commentId);
				}

				if (updatedComment) {
					set(updatedComment, 'body.value', JSON.stringify(adf));
					set(updatedComment, 'version.number', version + 1);
					cache.writeQuery({
						query: InlineCommentsQuery,
						variables: { pageId },
						data,
					});
				}
			},
		};

		return updateInlineComment({ ...mutationVariables })
			.then(({ data }) => {
				onSuccess();

				const { updateComment } = data;

				fireSuccessEvent(updateComment);
				resetComment();

				experienceTracker.succeed({
					name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
				});
			})
			.catch((error) => {
				return handleError(error);
			});
	};

	const handleCancel = useCallback(() => {
		resetComment();
	}, [resetComment]);

	const adf = content && content.length ? JSON.parse(content) : null;

	const handleEditorLoad = () => {
		experienceTracker.succeed({
			name: EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	return (
		<EditorContainer
			ref={editorRef}
			data-testid="inline-comment-edit-container"
			mode="edit"
			data-comment-id={commentId}
		>
			<CommentAuthor
				commentMode="edit"
				userId={userId}
				displayName={displayName}
				avatarUrl={avatarUrl}
			/>
			<CommentEditor
				pageId={pageId}
				pageType={pageType}
				spaceId={spaceId}
				appearance="chromeless"
				commentMode="edit"
				commentType="inline"
				content={adf}
				onSaveComment={handleSave}
				onCancelComment={handleCancel}
				showCancelButton
				useNewWarningModal
				onContentChange={onChange}
				onEditorReady={handleEditorLoad}
				shouldWarnOnInternalNavigation
				hideWatchCheckbox
				pageMode={mode}
				hasMediaUploadPermissions={Boolean(canUploadMedia)}
			/>
		</EditorContainer>
	);
};
