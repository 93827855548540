import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { DocNode } from '@atlaskit/adf-schema';

import {
	REPLY_TO_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	ADD_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentEditor, InlineCommentEditor } from '@confluence/comment';
import {
	InlineCommentsContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { getMatchTraces } from '@confluence/highlight-actions';
import { markErrorAsHandled } from '@confluence/graphql';
import { useSearchSessionId } from '@confluence/search-session';
import {
	CreateInlineCommentMutation,
	InlineCommentsQuery,
	CommentButtonRefetchQuery,
	CreateInlineReplyMutation,
	CommentCreationLocation,
} from '@confluence/inline-comments-queries';
import type { InlineCommentsQueryType } from '@confluence/inline-comments-queries';
import { CommentAuthor } from '@confluence/inline-comments-common';
import {
	InlineCommentError,
	InlineCommentFramework,
} from '@confluence/inline-comments-common/entry-points/enum';
import { EditorContainer } from '@confluence/inline-comments-common/entry-points/styled';
import {
	convertDOMHighlightToMark,
	CREATE_COMMENT_SELECTION_CLASS,
} from '@confluence/comments-util/entry-points/domUtils';
import {
	parseError,
	getTranslatedError,
	isHighlightError,
	isUnexpectedError,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import type {
	InlineCommentsMode,
	UserToMention,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { END } from '@confluence/navdex';

type OnSaveComment = (adf: any, onSuccess: () => void) => Promise<any>;

/**
 * `useHandleSaveWorkaround` is used to workaround an issue in editor-core
 * where the reference of the onSave function is not current - this stores the current
 * save function in a reference and calls that reference to ensure we always call the latest
 * handler.
 *
 * @param handleSave original handleSave function
 * @returns stored handleSave function
 */
export function useHandleSaveWorkaround(handleSave: OnSaveComment): OnSaveComment {
	const handleSaveRef = useRef(handleSave);
	useEffect(() => {
		handleSaveRef.current = handleSave;
	}, [handleSave]);

	return useCallback((adf: any, onSuccess: any) => {
		return handleSaveRef.current(adf, onSuccess);
	}, []);
}

type CreateCommentProps = {
	pageId: string;
	pageType: string;
	spaceId: string;
	isFabricPage?: boolean;
	isReply?: boolean;
	userId?: string | null;
	parentId?: string;
	onNewCommentSuccess?: (newMarkerRef?: string) => void;
	shouldShowReplyEditor?: boolean;
	userToMention?: UserToMention;
	lastFetchTime?: number;
	mode: InlineCommentsMode;
	canUploadMedia: boolean;
	isFirstComment?: boolean;
};

export const CreateComment: FC<CreateCommentProps> = ({
	pageId,
	pageType,
	spaceId,
	isFabricPage,
	isReply,
	userId,
	parentId,
	onNewCommentSuccess,
	shouldShowReplyEditor,
	userToMention,
	lastFetchTime,
	mode,
	canUploadMedia,
}) => {
	const [shouldResetEditor, setShouldResetEditor] = useState(false);
	const [mention, setMention] = useState<DocNode | undefined>();

	// WS-2432 Refetch CommentButtonRefetchQuery allows us to re-render Comment Button when user creates a Inline comment to
	//         correctly go to first inline comment on a page and get the proper count next to it
	const [createInlineComment] = useMutation(CreateInlineCommentMutation, {
		refetchQueries: [{ query: CommentButtonRefetchQuery, variables: { pageId } }],
	});

	// LOVE-85 Triggering a refetch of the CommentButtonQuery to update the count when a reply is added
	const [createInlineReply] = useMutation(CreateInlineReplyMutation, {
		refetchQueries: [{ query: CommentButtonRefetchQuery, variables: { pageId } }],
	});

	const { onChange, resetContentChanged } = useCommentContentDispatchContext();
	const { newCommentHighlight, highlightOrigin, toggleSidebar } = useContext(InlineCommentsContext);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [{ searchSessionId }] = useSearchSessionId();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		// will not open the Reply editor in CommentEditor without this check
		if (isReply && !shouldShowReplyEditor) {
			setShouldResetEditor(true);
		}
	}, [parentId, isReply, shouldShowReplyEditor]);

	useEffect(() => {
		if (isReply && userToMention?.accountId && userToMention?.displayName) {
			const newReplyCommentWithMention: DocNode = {
				version: 1,
				type: 'doc',
				content: [
					{
						type: 'paragraph',
						content: [
							{
								type: 'mention',
								attrs: {
									id: userToMention.accountId,
									text: '@'.concat(userToMention.displayName),
								},
							},
							{ text: ' ', type: 'text' },
						],
					},
				],
			};

			setMention(newReplyCommentWithMention);
		}
	}, [isReply, userToMention]);

	const handleInvalidHighlight = (errorId, message) => {
		const attributes: any = {
			errorMessage: message,
			editor: 'FABRIC',
			framework: InlineCommentFramework.REACT,
			errorId,
			highlightOrigin,
		};

		// We need to re-select the selection, get the range based on the selection
		const selectedRange = new Range();
		const selectionNode = document.querySelector(`.${CREATE_COMMENT_SELECTION_CLASS}`);

		if (selectionNode) {
			selectedRange.selectNode(selectionNode);

			// Select it
			const selection = document.getSelection();

			if (selection) {
				selection.removeAllRanges();
				selection.addRange(selectedRange);

				// Now get the matches
				const matchList = getMatchTraces(
					document.querySelector('#content'),
					selection.getRangeAt(0),
					pageId,
				);

				attributes.matchTrace = JSON.stringify(matchList);
			}
		}

		// Send the analytics event
		const analyticsObject = {
			source: 'viewPage',
			objectType: 'page',
			objectId: pageId,
			action: 'invalid',
			actionSubject: 'highlight',
			actionSubjectId: 'inlineComment',
			attributes,
		};

		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: analyticsObject,
		}).fire();
	};

	const handleError = (error: Error) => {
		markErrorAsHandled(error); //since we handle Inline comment errors, use markErrorAsHandled to avoid sending ui unhandled Errors
		const { errorId, message } = parseError(error);
		const translatedError = getTranslatedError(message, isReply ? parentId : '');

		// WS-2176 - On an invalid highlight, we want to trace back the matches found on
		// the frontend and build out a tree from the parent div#content element down to
		// the text node where we actually matched the text
		if (isHighlightError(translatedError)) {
			handleInvalidHighlight(errorId, message);
		}

		// WS-2629 - We only want to fail the experience for an error that is unexpected
		if (isUnexpectedError(translatedError)) {
			experienceTracker.stopOnError({
				error,
				name: isReply
					? REPLY_TO_INLINE_COMMENT_PUBLISH_EXPERIENCE
					: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			});
		}

		return Promise.reject({ error: translatedError });
	};

	const getCacheUpdateFn = () => {
		return (cache, { data: mutationData }) => {
			const response: InlineCommentsQueryType = cache.readQuery({
				query: InlineCommentsQuery,
				variables: { pageId },
			});

			const nodes = response.comments?.nodes || [];
			const contentData = response.content;

			const newNodes = [...nodes];

			if (isReply) {
				// find the parent comment
				const parentComment = newNodes.find((comment) => comment?.id === parentId);

				// add the new reply
				parentComment?.replies?.push?.(mutationData.replyInlineComment);
			} else {
				// add the new parent comment
				newNodes.push(mutationData.createInlineComment);
			}

			cache.writeQuery({
				query: InlineCommentsQuery,
				variables: { pageId },
				data: {
					content: contentData,
					comments: {
						nodes: newNodes,
						__typename: 'PaginatedCommentList',
					},
				},
			});
		};
	};

	const getMutationVariableInput = (adf) => {
		if (isReply) {
			return {
				containerId: pageId,
				parentCommentId: parentId,
				commentBody: {
					value: JSON.stringify(adf),
					representationFormat: 'ATLAS_DOC_FORMAT',
				},
				commentSource: 'WEB',
				createdFrom: CommentCreationLocation.RENDERER,
			};
		}

		// Add new comment
		if (!lastFetchTime || !newCommentHighlight) {
			throw new Error("Invalid variable input for 'create' comment");
		}

		// New ProseMirror step for the new collab service
		const { step } = newCommentHighlight;

		// Extract the legacy selection info
		const { numMatches, matchIndex, selectedText } = newCommentHighlight;

		const mutationInput = {
			containerId: pageId,
			commentBody: {
				value: JSON.stringify(adf),
				representationFormat: 'ATLAS_DOC_FORMAT',
			},
			numMatches,
			matchIndex,
			originalSelection: selectedText.replace(/\n/g, ''),
			createdFrom: 'RENDERER',
			lastFetchTimeMillis: lastFetchTime,
			commentSource: 'WEB',
		};

		if (step) {
			mutationInput['step'] = step;
		}

		return mutationInput;
	};

	const fireSuccessEvent = ({ id }) => {
		const analyticsObject = {
			containerType: 'space',
			containerId: spaceId,
			source: 'viewPageScreen',
			objectType: 'page',
			objectId: pageId,
			action: 'created',
			actionSubject: 'comment',
			actionSubjectId: id,
			attributes: {
				commentType: 'inline',
				pageType,
				parentCommentId: parentId ?? null, // analytics event schema type expects string or null
				mode: 'view',
				context: 'default',
				framework: 'REACT',
				searchSessionId,
				navdexPointType: END,
			},
		};

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: analyticsObject,
		}).fire();
	};

	const handleSuccess = (data) => {
		if (isReply) {
			const { replyInlineComment: newReply } = data;

			fireSuccessEvent(newReply);
			onNewCommentSuccess && onNewCommentSuccess();

			setMention(undefined);

			experienceTracker.succeed({
				name: REPLY_TO_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			});
		} else {
			const { createInlineComment: newComment } = data;

			const markerRef = newComment.location.inlineMarkerRef;

			if (!markerRef) {
				// TODO: Fail if we can't make the mark in the DOM permanent?
				return handleError(new Error("Can't make the mark on the DOM permanent"));
			}

			// Now that we've created the comment, we need to convert the highlight
			// made by the user into a mark that "mimics" a real inline comment mark
			// so the user can interact with it in the currently loaded page
			convertDOMHighlightToMark(Boolean(isFabricPage), markerRef);

			fireSuccessEvent(newComment);

			// successful experience tracking for inline comment publish
			experienceTracker.succeed({
				name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			});

			// On a successful create, we need to select the new comment
			onNewCommentSuccess && onNewCommentSuccess(markerRef);
		}
	};

	const handleSave = (adf, onSuccess) => {
		let mutationFn;
		let mutationVariables;

		experienceTracker.start({
			name: isReply
				? REPLY_TO_INLINE_COMMENT_PUBLISH_EXPERIENCE
				: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			attributes: {
				mode,
				highlightOrigin,
			},
		});

		try {
			mutationFn = isReply ? createInlineReply : createInlineComment;
			mutationVariables = {
				variables: {
					input: getMutationVariableInput(adf),
				},
				update: getCacheUpdateFn(),
			};
		} catch (e) {
			return handleError(e);
		}

		return mutationFn({ ...mutationVariables })
			.then(({ data }) => {
				onSuccess();

				return handleSuccess(data);
			})
			.catch((error) => {
				let augmentedError = error;

				if (isReply) {
					// If a null pointer exception with a null message occurs this is an attempted reply to a deleted comment
					if (error.message.includes('java.lang.NullPointerException: null')) {
						augmentedError = new Error(InlineCommentError.CANNOT_REPLY_TO_DELETED_PARENT);
					}
				}

				return handleError(augmentedError);
			});
	};

	const handleSaveCurrent = useHandleSaveWorkaround(handleSave);

	const handleCancel = () => {
		resetContentChanged();

		setMention(undefined);

		if (!isReply) {
			toggleSidebar(false);
		}

		experienceTracker.abort({
			name: isReply ? REPLY_TO_INLINE_COMMENT_EXPERIENCE : ADD_INLINE_COMMENT_EXPERIENCE,
			reason: isReply ? 'cancelled reply' : 'cancelled new comment',
		});
	};

	// successful experience tracking for inline comment load
	const handleEditorLoad = () => {
		if (!isReply) {
			resetContentChanged();
		}

		experienceTracker.succeed({
			name: isReply ? REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE : ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	const handleReset = () => {
		setShouldResetEditor(false);
	};

	/* InlineCommentEditor is a Loadable that displays a Input box during loading with Reply
  placeholder, CommentEditor displays a spinner*/
	const Editor = isReply ? InlineCommentEditor : CommentEditor;

	return (
		<EditorContainer isReply={isReply} data-testid="inline-comment-create-container" mode={mode}>
			<CommentAuthor
				size={isReply ? 'small' : 'medium'}
				commentMode={isReply ? 'reply' : 'create'}
				userId={userId}
			/>
			<Editor
				newCommentHighlight={newCommentHighlight}
				pageId={pageId}
				pageType={pageType}
				spaceId={spaceId}
				appearance="chromeless"
				commentMode={isReply ? 'reply' : 'create'}
				commentType="inline"
				showCancelButton={isReply}
				onSaveComment={handleSaveCurrent}
				onCancelComment={handleCancel}
				useNewWarningModal
				onEditorReady={handleEditorLoad}
				onContentChange={onChange}
				shouldResetEditor={shouldResetEditor}
				onEditorReset={handleReset}
				expandEditor={shouldShowReplyEditor}
				shouldWarnOnInternalNavigation
				hideWatchCheckbox
				pageMode={mode}
				hasMediaUploadPermissions={canUploadMedia}
				content={mention}
			/>
		</EditorContainer>
	);
};
