import type { FC } from 'react';
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState, memo } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import uuid from 'uuid/v4';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useUnreadInlineComments, useUnreadCommentsIsEnabled } from '@confluence/unread-comments';
import {
	InlineCommentMode,
	InlineCommentFramework,
} from '@confluence/inline-comments-common/entry-points/enum';
import {
	ReloadType,
	ReloadPriority,
	useQuickReloadSubscriber,
} from '@confluence/quick-reload/entry-points/subscription';
import {
	ADD_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	VIEW_INLINE_COMMENT_EXPERIENCE,
	ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
	ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	startEditorExperiences,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { ContentRefetchHandler } from '@confluence/content-body';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
	UnauthorizedErrorBoundary,
} from '@confluence/error-boundary';
import {
	ReactionsProvider,
	InlineCommentsContext,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { FloatingBannerStateContainer } from '@confluence/banners';
import { DialogsStateContainer } from '@confluence/dialogs';
import { FlagsStateContainer } from '@confluence/flags';
import { AccessStatus, useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
	GeneralShortcutListener,
} from '@confluence/shortcuts';
import { InlineCommentsQuery } from '@confluence/inline-comments-queries';
import { FocusedInlineCommentQuery } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import type {
	InlineCommentsQueryType,
	InlineCommentsQueryVariables,
	InlineCommentsCommentLocation,
} from '@confluence/inline-comments-queries';
import { i18n } from '@confluence/inline-comments-common/entry-points/i18n';
import {
	getNextMarkerRef,
	scrollToComment,
	getHighlightFromMarkerRef,
	scrollCommentIntoView,
	clearActiveHighlight,
	resetTemporaryDOMHighlight,
	getSortedMarkerRefs,
	setCommentAsActive,
	getHighlightOffset,
	getCommentIndexAndCount,
} from '@confluence/comments-util/entry-points/domUtils';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useIsPageCommentsAutoReloadEnabled } from '@confluence/page-comments/entry-points/feature-flags';

import {
	IMPROVED_INLINE_COMMENTS_NAVIGATION_FF,
	MENTION_IN_INLINE_COMMENT_REPLY_FROM_QUERY_PARAMS,
} from '../constants';

import { InlineCommentsLoadingSkeleton } from './InlineCommentsLoadingSkeleton';
import { InlineCommentSidebar } from './InlineCommentSidebar';

export type InlineCommentsProps = {
	pageId: string;
	isFabricPage: boolean;
};

export const InlineComments: FC<InlineCommentsProps> = memo(({ pageId, isFabricPage }) => {
	const {
		newCommentHighlight,
		clearCreateHighlight,
		highlightOrigin,
		activeHighlight,
		onHighlightClick,
		resolvedComment,
		setResolvedComment,
		setActiveHighlight,
	} = useContext(InlineCommentsContext);

	const [selectedMarkerRef, setSelectedMarkerRef] = useState<string>('');

	const [markerRefMap, setMarkerRefMap] = useState({});
	const [markerRefList, setMarkerRefList] = useState<string[]>([]);
	const [idToMarkerRefMap, setIdToMarkerRefMap] = useState({});
	const [shouldScrollToFocusedComment, setShouldScrollToFocusedComment] = useState(false);
	const [shouldOpenCommentEditor, setShouldOpenCommentEditor] = useState(false);
	const [shouldShowReplyEditor, setShouldShowReplyEditor] = useState(false);
	const [queryParamId, setQueryParamId] = useState<string | null>(null);
	const [lastFetchTime, setLastFetchTime] = useState(0);
	const [shouldRefetchContent, setShouldRefetchContent] = useState(false);

	// HOT-93738 - Add a first render flag to fix refetch issue
	const isFirstRender = useRef(true);
	const mountTimestamp = useRef(0);

	const activeHighlightRef = useRef<string | null>(null);
	const { userId, accessStatus } = useSessionData();

	const { hasContentChanged, isCommentContentReady } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const useImprovedInlineCommentsNavigation = useBooleanFeatureFlag(
		IMPROVED_INLINE_COMMENTS_NAVIGATION_FF,
	);
	const isUnreadCommentsEnabled = useUnreadCommentsIsEnabled();
	const isPageCommentsAutoReloadEnabled = useIsPageCommentsAutoReloadEnabled();
	const isMentionInReplyFromQueryParamsEnabled = useBooleanFeatureFlag(
		MENTION_IN_INLINE_COMMENT_REPLY_FROM_QUERY_PARAMS,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		focusedCommentId,
		editCommentId,
		replyToCommentId,
		shouldMentionInNewCommentEditor,
		removeCommentQueryParams,
	} = useInlineCommentQueryParams();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();

	const {
		data,
		loading,
		error,
		refetch: refetchComments,
	} = useQuery<InlineCommentsQueryType, InlineCommentsQueryVariables>(InlineCommentsQuery, {
		variables: {
			pageId,
		},
		fetchPolicy:
			window.__SSR_RENDERED__ && isFirstRender.current && !focusedCommentId
				? 'cache-first'
				: 'cache-and-network',
		skip: !pageId,
	});

	useQuickReloadSubscriber({
		name: 'commentsInline',
		types: [ReloadType.content, ReloadType.comment],
		reload: refetchComments,
		priority: ReloadPriority.background,
	});

	/* We only run below 2 queries if following conditions are true*/
	const skipQuery = Boolean(
		data ||
			!pageId ||
			!focusedCommentId ||
			!window.__SSR_RENDERED__ ||
			!window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__?.['focusedComment'],
	);

	/* We preload FocusedInlineComment data using FocusedInlineCommentQuery and display in inline-comments/src/ssr-mode/FocusedInlineComment.tsx
    in ssr. When the SPA loads (this component) we want to load the component immediately if data is available
    */
	const { data: focusedCommentData, error: focusedCommentError } = useQuery(
		FocusedInlineCommentQuery,
		{
			variables: { commentId: focusedCommentId, contentId: pageId },
			fetchPolicy: 'cache-first',
			skip: skipQuery,
		},
	);

	const onSidebarClose = () => {
		// Clear the selected comment
		setSelectedMarkerRef('');
		removeCommentQueryParams(); //clear any query params from url
		onHighlightClick(null);
		activeHighlightRef.current = null;
		clearActiveHighlight();
		// Clear the create highlight and clear the DOM
		resetTemporaryDOMHighlight();
		clearCreateHighlight();
		resetContentChanged();

		// Abort experience tracking on sidebar close
		abortOngoingExperiences(
			[
				ADD_INLINE_COMMENT_EXPERIENCE,
				EDIT_INLINE_COMMENT_EXPERIENCE,
				REPLY_TO_INLINE_COMMENT_EXPERIENCE,
			],
			'sidebar closed',
		);
	};

	const isPermissionsError = (error: ApolloError | undefined) => {
		// WS-3934 - When a space/parent page is restricted we can get the message "Space is restricted" when
		//           the space is restricted or "Parent page view is restricted" when it's a parent page
		//           so to handle these errors in the same way we do 401/403 we just need to parse the message
		return isUnauthorizedError(error) || error?.message.includes('restricted');
	};

	/* Creating a new comment is a special case where we don't have any comment selected,
	 * so we need to tell the sidebar which comment to select upon successful creation.
	 * Similarly, creating a new reply requires a refetch as well to update the selected
	 * comment object and show it, but we omit the markerRef since it's already selected
	 */
	const onNewCommentSuccess = (newMarkerRef?: string) => {
		// New top-level comment
		if (newMarkerRef) {
			clearCreateHighlight();
			setShouldRefetchContent(true);
			setSelectedMarkerRef(newMarkerRef);
		}

		// Clear the content changed value
		resetContentChanged();

		// Refetching will trigger refMap and refList to be remade
		// WS-3934 - We need to handle permissions errors for refetches as they are contributing to
		//           the unhandled JS errors coming from InlineCommentsQuery
		refetchComments().catch((error) => {
			if (isPermissionsError(error)) {
				markErrorAsHandled(error);
			}
		});

		if (shouldMentionInNewCommentEditor) {
			removeCommentQueryParams();
		}
	};

	// Selects a comment by an id and returns true if it finds it, otherwise returns false
	const selectCommentById = useCallback(
		(id: any) => {
			const markerRefToSelect = idToMarkerRefMap[id];
			if (markerRefToSelect) {
				setSelectedMarkerRef(markerRefToSelect);
				setCommentAsActive(markerRefToSelect);
				return markerRefToSelect;
			}
			return false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[idToMarkerRefMap],
	);

	// Aborts the passed in experiences with the given reason
	const abortOngoingExperiences = useCallback(
		(experiences: any, reason: any) => {
			experiences.forEach((experienceName) =>
				experienceTracker.abort({
					name: experienceName,
					reason,
				}),
			);
		},
		[experienceTracker],
	);

	useEffect(() => {
		mountTimestamp.current = performance.now();
		setLastFetchTime(Date.now());

		// Update the ref to mark that the first render happened
		isFirstRender.current = false;
	}, []);

	useEffect(() => {
		setSelectedMarkerRef('');
		setShouldRefetchContent(false);

		// WS-2700 - If we're changing pages clear the new comment dialog
		return () => {
			clearCreateHighlight();
		};
		// WS-2610 - When the pageId changes or when we mount we want to remove the selected comment to avoid 0/0
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [pageId]);

	useEffect(() => {
		if (!data) {
			return;
		}

		/* clean up window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ only once data is ready since we get position and totalComments
      from this object via getCommentIndexAndCount */
		// Clean up window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__
		if (window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__) {
			delete window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__;
		}

		const comments = data.comments?.nodes || [];

		// We need to create a map that connects markerRefs to comments for quick lookup whenever the collection changes
		const refMap = comments.reduce((inlineRefAccumulator, comment) => {
			if (!comment) {
				return inlineRefAccumulator;
			}

			const { inlineMarkerRef } = comment.location as InlineCommentsCommentLocation;

			// We should always get top-level inline comments, but if this doesn't exist, just return
			if (!inlineMarkerRef) {
				return inlineRefAccumulator;
			}

			inlineRefAccumulator[inlineMarkerRef] = comment;

			return inlineRefAccumulator;
		}, {});

		// We need to create a map that connects comment/reply id's to a markerRef for quick lookup for queryParams
		const idMap = comments.reduce((idMapAccumulator, comment) => {
			if (!comment) {
				return idMapAccumulator;
			}

			const { id } = comment;
			const { inlineMarkerRef } = comment.location as InlineCommentsCommentLocation;

			// We should always get top-level inline comments, but if this doesn't exist, just return
			if (!inlineMarkerRef) {
				return idMapAccumulator;
			}

			idMapAccumulator[id] = inlineMarkerRef;

			comment.replies.forEach((reply) => {
				if (reply) {
					const { id: replyId } = reply;
					idMapAccumulator[replyId] = inlineMarkerRef;
				}
			});

			return idMapAccumulator;
		}, {});

		setMarkerRefMap(refMap);
		/* sort by position in DOM and set markerRefList - helps to navigate between comments */
		const sortedList = getSortedMarkerRefs(Object.keys(refMap));
		setMarkerRefList(sortedList);
		setIdToMarkerRefMap(idMap);
	}, [data, isFabricPage]);

	const startViewExperience = useCallback(
		({
			markerRef,
			isFocusedComment,
			commentId,
		}: {
			markerRef?: string;
			isFocusedComment?: boolean;
			commentId?: string;
		}) => {
			if (markerRef && markerRef.length) {
				const totalReplies = (markerRefMap[markerRef]?.replies || []).length;
				experienceTracker.start({
					name: VIEW_INLINE_COMMENT_EXPERIENCE,
					attributes: {
						totalReplies,
						mode: InlineCommentMode.VIEW,
						framework: InlineCommentFramework.REACT,
						isFocusedComment,
					},
				});
			} else {
				experienceTracker.start({
					name: VIEW_INLINE_COMMENT_EXPERIENCE,
					attributes: {
						mode: InlineCommentMode.VIEW,
						framework: InlineCommentFramework.REACT,
						isFocusedComment,
						commentId,
					},
				});
			}
		},
		[markerRefMap, experienceTracker],
	);

	const onCommentSelected = useCallback(
		(markerRef: string) => {
			// start experience before setShouldScrollToFocusedComment because we succeed in Effect so must fire before Effect fires
			startViewExperience({ markerRef });
			// should be before setSelectedMarkerRef so new comment does not also open its editor
			setShouldShowReplyEditor(false);
			setShouldScrollToFocusedComment(false);
			setShouldOpenCommentEditor(false);

			// Set the new comment
			setSelectedMarkerRef(markerRef);

			// Clear the create selections and clear the DOM
			resetTemporaryDOMHighlight();
			clearCreateHighlight();
			resetContentChanged();
			// Abort experience tracking on another comment selected
			abortOngoingExperiences(
				[
					ADD_INLINE_COMMENT_EXPERIENCE,
					EDIT_INLINE_COMMENT_EXPERIENCE,
					REPLY_TO_INLINE_COMMENT_EXPERIENCE,
				],
				markerRef.length ? 'new comment selected' : 'active comment closed',
			);
		},
		[clearCreateHighlight, startViewExperience, abortOngoingExperiences, resetContentChanged],
	);

	/* For Inline Highlights Performance the Highlighting and setting Event Listeners happens in InlineHighlighterNew and we update
  InlineCommentsContext on highlight click, using same onCommentSelected to set markerRef, experience tracking etc*/
	useEffect(() => {
		if (
			Object.keys(markerRefMap).length > 0 &&
			activeHighlight !== null &&
			activeHighlight !== activeHighlightRef.current
		) {
			onCommentSelected(activeHighlight);
			activeHighlightRef.current = activeHighlight;
		}
	}, [activeHighlight, markerRefMap, onCommentSelected]);

	useEffect(() => {
		if (newCommentHighlight) {
			// Abort experience tracking on new comment highlighted
			abortOngoingExperiences(
				[EDIT_INLINE_COMMENT_EXPERIENCE, REPLY_TO_INLINE_COMMENT_EXPERIENCE],
				'new comment highlighted',
			);

			clearActiveHighlight();
			// Clear any comment that's already selected
			setShouldScrollToFocusedComment(false);
			setShouldOpenCommentEditor(false);
			setShouldShowReplyEditor(false);
			setSelectedMarkerRef('');

			startEditorExperiences(
				/* compoundExperience */ {
					name: ADD_INLINE_COMMENT_EXPERIENCE,
					id: uuid(),
					attributes: {
						mode: InlineCommentMode.VIEW,
						framework: InlineCommentFramework.REACT,
						highlightOrigin,
					},
				},
				/* editorLoadExperience */ {
					name: ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
				},
				/* editorPublishExperience */ {
					name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
				},
			);
		}
	}, [newCommentHighlight, experienceTracker, abortOngoingExperiences, highlightOrigin]);

	const resetActiveHighlight = () => {
		activeHighlightRef.current = null;
		setActiveHighlight(null);
	};

	const handleFocusedCommentExperience = useCallback(
		(commentId: any) => {
			startViewExperience({
				isFocusedComment: true,
				commentId,
			});
			// abort View Inline comment experience
			experienceTracker.abort({
				name: VIEW_INLINE_COMMENT_EXPERIENCE,
				reason: 'Focused comment not found',
			});
		},
		[startViewExperience, experienceTracker],
	);

	// We need to select the top-level comment on a focus, edit, or reply
	useEffect(() => {
		// If the focused comment is different than the one already focused via query params, re-focus it
		if (
			Object.keys(idToMarkerRefMap).length > 0 &&
			focusedCommentId &&
			queryParamId !== focusedCommentId
		) {
			const markerRef = selectCommentById(focusedCommentId);
			if (markerRef) {
				startViewExperience({ markerRef, isFocusedComment: true });
				setQueryParamId(focusedCommentId);
				setShouldScrollToFocusedComment(true);
			} else {
				void handleFocusedCommentExperience(focusedCommentId);
			}
		}
	}, [
		focusedCommentId,
		queryParamId,
		selectCommentById,
		startViewExperience,
		experienceTracker,
		idToMarkerRefMap,
		handleFocusedCommentExperience,
	]);

	useEffect(() => {
		// If the edited comment is different than the one already edited via query params, re-focus it
		if (editCommentId && queryParamId !== editCommentId) {
			if (selectCommentById(editCommentId)) {
				setQueryParamId(editCommentId);
				setShouldOpenCommentEditor(true);
			}
		}
	}, [queryParamId, editCommentId, selectCommentById]);

	// We need to select the top-level comment on a replyTo, edit, or reply
	useEffect(() => {
		// If the replyTo comment is different than the one already replyTo via query params, re-focus it
		if (replyToCommentId && queryParamId !== replyToCommentId) {
			if (selectCommentById(replyToCommentId)) {
				setQueryParamId(replyToCommentId);
				setShouldShowReplyEditor(true);
			}
		}
	}, [queryParamId, replyToCommentId, selectCommentById]);

	useEffect(() => {
		const isNewNavigationLogic =
			useImprovedInlineCommentsNavigation &&
			focusedCommentId &&
			selectedMarkerRef == idToMarkerRefMap[focusedCommentId];
		const isOldNavigationLogic = !useImprovedInlineCommentsNavigation;
		if (selectedMarkerRef.length && (isNewNavigationLogic || isOldNavigationLogic)) {
			scrollCommentIntoView(getHighlightFromMarkerRef(selectedMarkerRef), isFabricPage, true);
		}
	}, [
		focusedCommentId,
		selectedMarkerRef,
		idToMarkerRefMap,
		isFabricPage,
		useImprovedInlineCommentsNavigation,
	]);

	const getCommentsCount = () => {
		if (focusedCommentData) {
			const { totalComments } = getCommentIndexAndCount();
			return totalComments;
		}
	};

	const getCommentIndex = useCallback(
		(currentMarkerRef: any) => {
			if (markerRefList && markerRefList.length > 0) {
				return markerRefList.findIndex((markerRef) => markerRef === currentMarkerRef);
			} else if (focusedCommentData) {
				const { commentIndex } = getCommentIndexAndCount();
				return commentIndex;
			}
		},
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
		[markerRefList],
	);

	const navigationListener = (event, dialogs) => {
		if (!event) return;
		if (event.key === NEXT_COMMENT_SHORTCUT) {
			navigateToComment('next', markerRefMap[selectedMarkerRef], dialogs, false);
		} else if (event.key === PREV_COMMENT_SHORTCUT) {
			navigateToComment('previous', markerRefMap[selectedMarkerRef], dialogs, false);
		}
	};

	const navigateToComment = (
		action: 'next' | 'previous',
		comment: Comment,
		dialogs: DialogsStateContainer,
		hasClickedArrowIcons: boolean,
	) => {
		const doNavigate = (key: 'next' | 'previous', markerRef?: string) => {
			resetContentChanged();
			clearActiveHighlight();

			const currentMarkerRefIndex = getCommentIndex(markerRef);
			const nextMarkerRef = getNextMarkerRef(key, currentMarkerRefIndex, markerRefList);
			if (nextMarkerRef) {
				onHighlightClick(nextMarkerRef);
				setCommentAsActive(nextMarkerRef);
				if (useImprovedInlineCommentsNavigation) {
					scrollToComment(
						getHighlightFromMarkerRef(nextMarkerRef),
						getHighlightFromMarkerRef(markerRef),
						isFabricPage,
					);
				}
			}
		};

		const { inlineMarkerRef } = comment.location;
		if (hasContentChanged) {
			dialogs.showDialog(CommentWarningDialog, {
				onConfirm: () => doNavigate(action, inlineMarkerRef),
			});
		} else {
			doNavigate(action, inlineMarkerRef);
		}

		if (hasClickedArrowIcons) {
			fireAnalyticEvent();
		}
	};

	const fireAnalyticEvent = () => {
		const analyticObj = {
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigate',
				actionSubjectId: 'inlineCommentArrowsNavigate',
				attributes: {
					unreadCommentCount: unreadCommentsListState?.length,
				},
				objectType: 'inlineComment',
				objectId: pageId,
				source: 'viewPageScreen',
			},
		};
		createAnalyticsEvent(analyticObj).fire();
	};

	// If we refetch, we don't want to show a null component
	const content = (focusedCommentData || data)?.content?.nodes?.[0];
	const spaceId = content?.space?.id!;
	const pageType = content?.type!;
	const operations = content?.operations || [];
	const canCreateComments = Boolean(
		operations.find((op) => op?.operation === 'create' && op?.targetType === 'comment'),
	);
	// The user can upload media only if they have update permissions for the page
	const canUploadMedia = Boolean(
		operations.find((op) => op?.operation === 'update' && op?.targetType === pageType),
	);

	const renderInlineCommentsLoader = () => {
		// Render skeleton if there was an ssr click on highlight
		if (
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements']
		) {
			return <InlineCommentsLoadingSkeleton />;
			/* Handle SPA - If a user clicks too quickly when SPA loads*/
		} else if (activeHighlight) {
			const offset = getHighlightOffset(
				isFabricPage,
				false,
				activeHighlight,
				isPageCommentsAutoReloadEnabled,
			);
			return <InlineCommentsLoadingSkeleton offset={offset} />;
		}

		return null;
	};

	const getFocusedCommentData = () => {
		if (window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__?.['focusedComment']?.['isTopLevelComment']) {
			return focusedCommentData?.comments?.nodes?.[0];
		} else {
			return focusedCommentData?.comments?.nodes?.[0]?.ancestors?.[0];
		}
	};

	const getOffset = () => {
		if (activeHighlight)
			return getHighlightOffset(
				isFabricPage,
				false,
				activeHighlight,
				isPageCommentsAutoReloadEnabled,
			);
		return 0;
	};

	if (error || focusedCommentError) {
		const graphqlError = error || focusedCommentError;

		// If user doesn't have permissions, handle the error and fail gracefully
		if (isPermissionsError(graphqlError)) {
			markErrorAsHandled(graphqlError);
			return null;
		}

		return <ErrorDisplay error={graphqlError as Error} />;
	}

	if (!data && loading && !focusedCommentData) {
		return renderInlineCommentsLoader();
	}

	// For transitions and VQR, current pageId is compared with cachedPageInfo's content.id (pageId)
	// to determine if we need to return null
	if (content?.id && content?.id !== pageId) {
		return null;
	}

	return (
		<ErrorBoundary attribution={Attribution.COLLABORATION}>
			<Subscribe to={[DialogsStateContainer, FloatingBannerStateContainer, FlagsStateContainer]}>
				{(
					dialogs: DialogsStateContainer,
					floatingBanners: FloatingBannerStateContainer,
					flags: FlagsStateContainer,
				) => (
					<UnauthorizedErrorBoundary
						renderOnError={() => {
							void flags.showErrorFlag({
								title: <FormattedMessage {...i18n.actionNotPermittedTitle} />,
								description: <FormattedMessage {...i18n.actionNotPermitted} />,
							});
							return null;
						}}
					>
						<ReactionsProvider contentId={pageId}>
							<Fragment>
								<InlineCommentSidebar
									pageId={pageId}
									pageType={pageType}
									spaceId={spaceId}
									onClose={onSidebarClose}
									onDeleteSuccess={refetchComments}
									onNewCommentSuccess={onNewCommentSuccess}
									canReplyToComment={canCreateComments}
									userId={userId}
									comment={markerRefMap[selectedMarkerRef] || getFocusedCommentData()}
									newCommentHighlight={newCommentHighlight}
									shouldShowFocusedComment={shouldScrollToFocusedComment}
									shouldOpenCommentEditor={shouldOpenCommentEditor}
									shouldShowReplyEditor={shouldShowReplyEditor}
									mentionCommentId={
										isMentionInReplyFromQueryParamsEnabled &&
										shouldMentionInNewCommentEditor &&
										(accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
											accessStatus === AccessStatus.LICENSED_USE_ACCESS)
											? replyToCommentId
											: undefined
									}
									isFabricPage={isFabricPage}
									navigateToComment={(action, comment) =>
										navigateToComment(action, comment, dialogs, true)
									}
									currentCommentIndex={getCommentIndex(selectedMarkerRef)}
									commentsCount={markerRefList.length || getCommentsCount()}
									refetchComments={refetchComments}
									dialogs={dialogs}
									floatingBanners={floatingBanners}
									lastFetchTime={lastFetchTime}
									canUploadMedia={canUploadMedia}
									isSSRFocusedComment={Boolean(focusedCommentData)}
									resolvedComment={resolvedComment}
									setResolvedComment={setResolvedComment}
									resetActiveHighlight={resetActiveHighlight}
									isUnreadCommentsEnabled={isUnreadCommentsEnabled}
								/>
								{!isCommentContentReady && <InlineCommentsLoadingSkeleton offset={getOffset()} />}
								{selectedMarkerRef && (
									<GeneralShortcutListener
										accelerator={[NEXT_COMMENT_SHORTCUT, PREV_COMMENT_SHORTCUT]}
										listener={(event) => navigationListener(event, dialogs)}
									/>
								)}
								<ContentRefetchHandler contentId={pageId} shouldRefetch={shouldRefetchContent} />
							</Fragment>
						</ReactionsProvider>
					</UnauthorizedErrorBoundary>
				)}
			</Subscribe>
		</ErrorBoundary>
	);
});
